import React from 'react'
import {
    Button,
    Container, Paper, TextField, Toolbar
} from '@mui/material'

export default function Login(){


    return(
        <Container maxWidth="sm">
            
            <Paper variant='outlined' style={{padding: 30,paddingLeft: 60,paddingRight: 60,marginTop: 40}}>
                <h2>Login to student portal</h2>
                <hr color="#e77817" style={{marginBottom:30}} />
                <TextField 
                    label="Email"
                    variant='outlined'
                    fullWidth
                    style={{marginTop: 10}}
                />
                <TextField 
                    label="Password"
                    variant='outlined'
                    fullWidth
                    style={{marginTop: 10}}
                />
                <Button color="primary" style={{textTransform:'none'}}>
                    Forgot Password ?
                </Button>
                <div style={{justifyContent:'space-between',marginTop:20,display:'flex'}}>
                    <Button style={{textTransform:'none'}} color="primary">
                        Haven't Registred yet?
                    </Button>
                    <Button
                        variant='contained'
                        color="primary"
                    >
                        Login
                    </Button>
                </div>
            </Paper>
        </Container>
    )
}