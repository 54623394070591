import React from 'react'
import { 
    Container,
    Paper,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    Button
} from '@mui/material'
import { Api, ImageApi } from '../../consts/api';
import Slider from "react-slick";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};
export default function GalleryMin(){
    const [data, setData] = React.useState([])
    React.useState(()=>{
        fetch(Api+'galleryMin')
        .then(res=>res.json())
        .then(res=>setData(res))
    },[])

    return(
        <Container maxWidth="md" style={{display:'flex',flexDirection:'column'}}>
            <h1 style={{marginTop: 0}}>Top Gallery</h1>
            <Slider {...settings}>
          
          {
            data.map((item,index)=>
            <div key={index}>
              <Card sx={{ maxWidth: 445,marginRight: 1 }}>
                <CardActionArea>
                    <CardMedia
                    component="img"
                    height="140"
                    image={ImageApi+'gallery/'+item.image}
                    alt={item.image}
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {item.caption}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {item.description}
                    </Typography>
                    </CardContent>
                </CardActionArea>
                </Card>
            </div>
            
            )
          }
            
          </Slider>
          <Button color='primary' variant='contained' style={{right:0,alignSelf:'flex-end',maxWidth: 250}}>
            Explore More
          </Button>
        </Container>
    )
}