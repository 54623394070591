const api_key = "a4384cce15b8f880842a76da40fb5f70"
// const Api = "http://192.168.43.253/herosis/?api="+api_key+"&q="
// const ImageApi = "http://192.168.43.253/herosis/uploads/"

const Api = "https://gnosis-institute.in/api/api.php?api_key="+api_key+"&q="
const ImageApi = "https://gnosis-institute.in/api/assets/"
export  {ImageApi,Api};
// const api_key = "ef7eb42d186239ac3a50602e0a9f9f38"
// const Api = "https://aamarbazaar.com/her_osis/api/?api="+api_key+"&q="
// const ImageApi = "https://aamarbazaar.com/her_osis/api/uploads/"
// export  {ImageApi,Api};




