import React from "react";
import { Button, Card, CardMedia, Container, Grid, Toolbar, Typography } from "@mui/material";

import Icon from '../assets/home_icon.png'
import Course from "./course/course";
import SendMessage from "./contact_us/contact_us";
import Reviews from "./reviews";
import AboutUsMin from "./about_us_home";
import Events from "./others/noticeandevent";
import GalleryMin from "./Gallery/gallery_min";
import {Api, ImageApi} from '../consts/api'
import Slider from "react-slick";
import { useMediaQuery,useTheme } from "@mui/material";
const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    
};
export default function Home(){
    const [taglines, setTaglines] = React.useState([])
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    React.useEffect(()=>{
        fetch(Api+'tagline')
        .then(res=>res.json())
        .then(res=>setTaglines(res))
    },[])

    return(
        <Container maxWidth="xl" style={{width:'100%',padding:0,margin: 0,overflow: 'hidden'}}>
            <Slider {...settings} >
                        {
                        taglines.map((item,index)=>
                            <div key={index}>
                                <div style={{width: '100%',backgroundImage :`url(${ImageApi+'tagline/'+item.img})`,backgroundRepeat: 'no-repeat',backgroundPosition: 'center',backgroundSize: '100% 100%',height : isMobile ? 550 : 450 }}>
                                <div style={{width: '100%',height : isMobile ? 550 : 450 ,backgroundColor : 'rgba(0,0,0,0.7)',color: '#fff',display : 'flex',flexDirection : 'column',justifyContent: 'center',alignItems : 'center'}}>
                                    <h1 style={{margin: 0}}>{item.caption}</h1>
                                    <h2>{item.sub_caption}</h2>
                                    {/* <Toolbar>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor  : ''
                                        }}
                                    >
                                        Register Now
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        style={{
                                            backgroundColor  : '',
                                            marginLeft: 5
                                        }}
                                    >
                                        Contact Now
                                    </Button>
                                    </Toolbar> */}
                                </div> 
                                </div>
                                
                            </div>
                        )
                        }
            </Slider>
            <Course />
            <Grid container spacing={2} style={{marginTop: 80,padding: 10}}>
                <Grid item xs={12} sm={4}>
                    <Events />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <GalleryMin />
                </Grid>
            </Grid>
            <AboutUsMin />
            <Grid container style={{marginTop: 50}}>
                <Grid item xs={12} sm={5}>
                    <SendMessage />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Reviews />
                </Grid>
            </Grid>
           
        </Container>
    )
}