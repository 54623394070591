import React from 'react'
import {
    Button,
    Container, LinearProgress, Paper, TextField, Toolbar
} from '@mui/material'
import SnakBar from '../../consts/massage'
import { Api } from '../../consts/api'
import { useNavigate } from 'react-router-dom'
export default function FacultyLogin(){
    const navigate = useNavigate()
    const [formData, setFormData] = React.useState({})
    const [err, setErr] = React.useState({})
    const [massg,setMassg] = React.useState({})
    const [loading,setLoading] = React.useState(false)

    React.useEffect(()=>{
        let email  = localStorage.getItem('email')
        if(!email){

        }else{
            navigate('/FacultyHome')
        }
    },[])
    const onChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const validate = ()=>{
        let errs = {}
        let valid = true

        if(!formData.email){
            
            errs['email'] = true
            valid = false
        } 
        if(!formData.pass){
            errs['pass'] = true
            valid = false
        }

        setErr(errs)

        return valid
    }

    const onSubmit = ()=>{
        if(validate()){
            setLoading(true)
                    fetch(Api+'facultyAuthentication',{
                        method : 'POST',
                        body : JSON.stringify(formData)
                    })
                    .then(res=>res.json())
                    .then(res=>{
                      setLoading(false)
                      if(res.code === 200){
                        localStorage.setItem('email',formData.email)
                        navigate('/FacultyHome')
                        setMassg({
                          open : true,
                          severity : 'success',
                          massg : res.massg
                        })
                      }else{
                        setMassg({
                          open : true,
                          severity : 'error',
                          massg : res.massg
                        })
                      }
                    })
                    .catch(err=>{
                      setLoading(false)
                      setMassg({
                        open : true,
                        severity : 'error',
                        massg : 'Faild to connect to the server'
                      })
                    })
        }else{
            setMassg({
                open : true,
                severity : 'error',
                massg : 'Please fill all required filled'
              })
        }
    }

    return(
        <Container maxWidth="sm">
            
            <Paper variant='outlined' style={{padding: 30,paddingLeft: 60,paddingRight: 60,marginTop: 40}}>
                <h2>Login to Faculty Portal</h2>
                <hr color="#e77817"  />
                {loading ? <LinearProgress /> : ''}
                <TextField 
                    label="Email"
                    variant='outlined'
                    fullWidth
                    style={{marginTop: 40}}
                    value={formData.email}
                    onChange={onChange}
                    error={err.email}
                    name="email"
                />
                <TextField 
                    label="Password"
                    variant='outlined'
                    fullWidth
                    style={{marginTop: 10}}
                    value={formData.pass}
                    onChange={onChange}
                    error={err.pass}
                    name="pass"
                    type="password"
                />
                <Button color="primary" style={{textTransform:'none'}}>
                    Forgot Password ?
                </Button>
                <div style={{justifyContent:'space-between',marginTop:20,display:'flex'}}>
                    <Button style={{textTransform:'none'}} color="primary">
                        Haven't Registred yet?
                    </Button>
                    <Button
                        variant='contained'
                        color="primary"
                        onClick={onSubmit}
                    >
                        Login
                    </Button>
                </div>
            </Paper>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}