import React from 'react'
import {
    Container,
    TextField,
    Button,
    Box,
    Typography,
    CircularProgress,
    Paper
} from '@mui/material'
import {IconContext} from "react-icons";
import {FaArrowRight} from 'react-icons/fa'
import { makeStyles,useTheme } from '@mui/styles';
import { Api } from '../../consts/api';

export default function SendMessage(){
    const [sendData,setSendData] = React.useState({})
    const [err, setErr] = React.useState({})
    const classes = useStyle()
    const [loading, setLoading] = React.useState(false)
    const onChange = (e)=>{
        setSendData({
            ...sendData,
            [e.target.name] : e.target.value
        })
    }
    const onSubmit = ()=>{
        if(valid()){
            setLoading(true)
            fetch(Api+'SendMassage',{
                method : 'POST',
                body : JSON.stringify(sendData)
            })
            .then(res=>res.json())
            .then(data=>{
                setLoading(false)
                alert(data.massg)
            })
            .catch(err=>{
                setLoading(false)
                alert('Faild to connect to the server')
            })
            
        }
    }

    const valid = ()=>{
        let error = {}
        let valid = true
        if(!sendData.f_name){
            valid = false
            error['f_name']  = true
        }
        if(!sendData.l_name){
            valid = false
            error['l_name']  = true
        }
        if(!sendData.email){
            valid = false
            error['email']  = true
        }
        if(!sendData.ph_no){
            valid = false
            error['ph_no']  = true
        }
        if(!sendData.massg){
            valid = false
            error['massg']  = true
        }

        setErr(error)
        return valid
    }

    return(
        <Container component={Paper} variant="outlined" maxWidth="md" style={{height: 500}}>
            <h1 style={{marginBottom : 0,marginLeft: 15}}>Send a message</h1>
            <Typography style={{marginBottom : 0,marginLeft: 15}}>For any query feel free to message us</Typography>
             <Box className={classes.form}>
                        <div className={classes.multiycol}>
                            <div className={classes.TextField}>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                fullWidth
                                name="f_name"
                                value={sendData.f_name}
                                onChange={onChange}
                                error={err.f_name}
                                
                            />
                            </div>
                            <div className={classes.TextField}>
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                name="l_name"
                                value={sendData.l_name}
                                onChange={onChange}
                                error={err.l_name}
                                fullWidth
                            />
                            </div>
                        </div>
                        <div className={classes.TextField}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={sendData.email}
                            onChange={onChange}
                            error={err.email}
                            fullWidth
                        />
                        </div>
                        <div className={classes.TextField}>
                        <TextField
                            label="Contact No"
                            variant="outlined"
                            className={classes.TextField}
                            fullWidth
                            name="ph_no"
                            type="number"
                            value={sendData.ph_no}
                            onChange={onChange}
                            error={err.ph_no}
                        />
                        </div>
                        <div className={classes.TextField}>
                        <TextField
                            label="Message"
                            variant="outlined"
                            className={classes.TextField}
                            fullWidth
                            multiline
                            name="massg"
                            value={sendData.massg}
                            onChange={onChange}
                            error={err.massg}
                        />
                        </div>
                        <Button 
                            color="primary" 
                            variant="contained" 
                            size="large"
                            fullWidth
                            onClick={onSubmit}
                            disabled={loading}
                        >    
                            {loading ? <CircularProgress /> : 'Send Message '}
                            <IconContext.Provider value={{ color: '#fff', style:{marginLeft :10} }}>
                            <div>
                                <FaArrowRight />
                            </div>
                            </IconContext.Provider>
                              
                        </Button> 
                        
                        
                        
                    </Box>
        </Container>
    )
}

const useStyle =  makeStyles({
    
    
    callhelp : {
        padding: 10,
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop : 30
    },
    heading : {
        fontFamily : 'Raleway',
        paddingLeft : 20,
        fontSize : 26,
        paddingTop: 20
    },
    
    multiycol : {
        display : 'flex',
        flexDirection: 'row',
        
    },
    TextField : {
        marginTop: 5,
        marginBottom : 5,
        marginLeft: 5,
    },
    form : {
        padding : 15,
        
    },
    btn : {
      //  backgroundColor: COLORS.primary,
        color: '#fff',
        marginLeft: 5,
        marginTop: 10,
        '&:hover' : {
           // backgroundColor: COLORS.primary,
            
        }
    },
    btnIcon:{
        color: '#fff',
        fill : 'white'
        
    },
    container : {
        border: '1px solid',
        borderColor: "#e5e5e5",
        borderRadius: 10,
    },
    rightCon : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
       
    }
})
