import React from 'react'
import {
    Container,
    Grid,
    Typography
} from '@mui/material'
import Events from './noticeandevent'
import { useLocation } from 'react-router-dom'
import DateString from '../../consts/dateHandler';

export default function IndividualNotice(){
    const {state} = useLocation();
    console.log(state)
    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <h1>{state.headline}</h1>
                    <Typography>{DateString(state.up_date)}</Typography>
                    <div dangerouslySetInnerHTML={{__html: state.content}}>

                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Events />
                </Grid>
            </Grid>
        </Container>
    )
}