import React from 'react'
import {
    Container,
    Paper,
    List,
    ListItem,
    ListItemText,
    Divider,
    Typography,
    Box

} from '@mui/material'
import colors from '../../consts/colors'
import DateString from '../../consts/dateHandler'
import {Api} from '../../consts/api'
import { useNavigate } from 'react-router-dom'


export default function Events(){
    const [data,setData] = React.useState([])
    const navigate = useNavigate()
    React.useEffect(()=>{
        fetch(Api+'notice')
        .then(res=>res.json())
        .then(data=>setData(data))
    },[])

    return (
        <Box component={Paper} className='Notice-Box'>
            <h3 className='notice-head' style={{backgroundColor: colors.primary,color: colors.white}}>Notice & Event</h3>
            <List className='notice-list'>
            {
                data.map((item,index)=>
                <div >
                    <div style={{padding : 10}}>
                    <ListItem key={index} onClick={()=>navigate('/IndividualNotice',{state : item})}>
                        <ListItemText
                            primary={<Typography style={{color: colors.primary,fontWeight: 'bold'}}>{item.headline}</Typography>}
                            secondary={DateString(item.up_date)}
                        />
                        
                    </ListItem>
                    <Typography className='notice-text' style={{marginLeft: 20}}>{item.short_description}</Typography>
                    </div>
                    <Divider />
                </div>

                )
            }
            </List>
        </Box>
    )
}