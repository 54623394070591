import React from 'react'
import { Api } from './api'

const AllCourses = ()=>{
    const [data, setData] = React.useState([])
    React.useEffect(()=>{
        fetch(Api+'allCourse')
        .then(res=>res.json())
        .then(res=>setData(res))
    },[])

    return data
}

export default AllCourses