import React from 'react'
import {Container, Grid} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { Api } from '../../consts/api'
import NotFound from '../404'
import Events from '../others/noticeandevent'
export default function CommonPage(){
    const location = useLocation()
    const [data, setData] = React.useState([])
    React.useEffect(()=>{
        fetch(Api+'contents')
        .then(res=>res.json())
        .then(res=>{
            setData(res)
        })
        .catch(err=>{
           console.log(err)
        })

       
    },[])
    return (
        <Container maxWidth="md" style={{marginTop: 50}}>
        {
            location.state != null ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <h1>{location.state.name}</h1>
                    <div dangerouslySetInnerHTML={{__html: location.state.content}}>

                    </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Events />
                    </Grid>
                </Grid>
            ) : (
                <NotFound />
            )
        }
        </Container>
    )
}