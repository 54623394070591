import React from "react";
import {
    Container,
    Typography,
    Button,
    Avatar,
    Paper
} from '@mui/material'
import Slider from "react-slick";
import { Api } from "../consts/api";

export default function Reviews(){
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    autoplay : true,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    };

    const [Reviews, setReviews] = React.useState([])
    React.useEffect(()=>{
      fetch(Api+'Reviews')
      .then(res=>res.json())
      .then(res=>setReviews(res))
      .catch(err=>{
         console.log(err)
      })
    },[])
    return(
        <Container component={Paper} variant="outlined" style={{height: 500}}>
            <h1>Testnomials</h1>
        <hr color="#e77817" style={{marginBottom:30}} />
        <Slider {...settings}>
        {
          Reviews.map((item,index)=>
          
          <div key={index}>
            <div style={{padding: 10,paddingTop: 0,display:'flex',alignItems:'center',flexDirection: 'column'}}>
            <Avatar component={Paper} style={{padding: 25,border: '5px solid #fff'}}>
                
            </Avatar>
            <h3>{item.name}</h3>
            <Typography style={{textAlign:'center'}}>
           {item.review}
            </Typography>
            </div>
          </div>
          )
        }
          
          
        </Slider>
        </Container>
    )

}