import { Container,Paper,Divider,TextField,LinearProgress,FormControl,InputLabel, Select, MenuItem, IconButton, Button, Tooltip } from '@mui/material'
import React from 'react'
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Save } from '@mui/icons-material'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Api } from '../../consts/api';
import SnakBar from '../../consts/massage';


const designation = ["Director","Assitant professor", "MTS"];
const department = ["Administration","Faculty","Office stuf"]
export default function ProfileUpdate(){
    
    const [sendData, setSendData] = React.useState({
        name : '',
        designation : '',
        department : '',
        email : '',
        ph_no : '',
        date_of_joining: ''
       
    })
    const [loading, setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const [err, setErr] = React.useState({})
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    const onChange = (e)=>{
        setSendData({
            ...sendData,
            [e.target.name] : e.target.value
        })
    }

    const onSubmit = ()=>{
        setLoading(true)
        fetch(Api+'updateFacultyProfile',{
          method : 'POST',
          body : JSON.stringify({data : draftToHtml(convertToRaw(editorState.getCurrentContent())),email : localStorage.getItem('email')})
        })
        .then(res=>res.json())
        .then(res=>{
          setLoading(false)
          if(res.code === 200){
            setMassg({
              open : true,
              severity : 'success',
              massg : res.massg
            })
          }else{
            setMassg({
              open : true,
              severity : 'error',
              massg : res.massg
            })
          }
        })
        .catch(err=>{
          setLoading(false)
          setMassg({
            open : true,
            severity : 'error',
            massg : 'Faild to connect to the server'
          })
        })
    }

    React.useEffect(()=>{
        fetch(Api+'profileInfo',{
            method : 'POST',
            body : JSON.stringify({
               email : localStorage.getItem('email')
            })
        })
        .then(res=>res.json())
        .then(res=>{
            setSendData({
                name : res[0].name,
                designation : res[0].designation,
                department : res[0].department,
                email : res[0].email,
                ph_no : res[0].phone_no,
                date_of_joining: res[0].date_of_joining
            })

            const html =  res[0].details;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            }
        })
        .catch(err=>{
           console.log(err)
        })
    },[])


    return(
        <Container>
           <Paper style={{padding: 10}}>
                    <h3>My Profile</h3>
                    <Divider />
                    {
                        loading ? <LinearProgress /> : ''
                    }
                    <TextField 
                     variant="outlined"
                     value={sendData.name}
                     onChange={onChange}
                     error={err.name}
                     label="Name"
                     name="name"
                     style={{marginTop: 10}}
                     fullWidth
                     disabled
                    />
                    
                    <TextField 
                     variant="outlined"
                     value={sendData.image}
                     onChange={onChange}
                     error={err.image}
                     helperText='Profile Image'
                     style={{marginTop: 10}}
                     type="file"
                     fullWidth
                     name="image"
                     disabled
                    />
                    {/* <TextField 
                     variant="outlined"
                     value={sendData.designation}
                     onChange={onChange}
                     error={err.designation}
                     label="Designation"
                     style={{marginTop: 10}}
                     fullWidth
                     name="designation"
                    /> */}
                    <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Designation</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sendData.designation}
                                
                                name="designation"
                                error={err.designation}
                                onChange={onChange}
                                disabled
                              >
                            {
                                designation.map((item,index)=><MenuItem key={index} value={item}>{item}</MenuItem>)
                            }
                              </Select>
                    </FormControl>
                    <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Department</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sendData.department}
                                
                                name="department"
                                error={err.department}
                                onChange={onChange}
                                disabled
                                
                              >
                            {
                                department.map((item,index)=><MenuItem key={index} value={item}>{item}</MenuItem>)
                            }
                              </Select>
                    </FormControl>
                    <TextField 
                     variant="outlined"
                     value={sendData.email}
                     onChange={onChange}
                     error={err.email}
                     label="Email"
                     style={{marginTop: 10}}
                     fullWidth
                     name="email"
                     disabled
                    />
                    <TextField 
                     variant="outlined"
                     value={sendData.ph_no}
                     onChange={onChange}
                     error={err.ph_no}
                     label="Contact No"
                     style={{marginTop: 10}}
                     fullWidth
                     name="ph_no"
                     disabled
                    />
                    <TextField 
                     variant="outlined"
                     value={sendData.date_of_joining}
                     onChange={onChange}
                     error={err.date_of_joining}
                     helperText="Joining Date"
                     style={{marginTop: 10}}
                     fullWidth
                     name="date_of_joining"
                     type="date"
                     disabled
                    />
                     <Editor
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onEditorStateChange={onEditorStateChange}
                        editorStyle={{border : '1px solid ',minHeight : 200, borderColor : err.editorState ? 'red' : '#e5e5e5'}} //err.editorState ? 'red' :
                        toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><Save /></Tooltip></IconButton>]}
                    
                        />
                        <textarea
                        disabled
                        style={{display: 'none'}}
                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                        // onChange={({target : {value}})=>setData(value)}
                    />
                    <Button
                     variant="contained"
                     style={{marginTop: 10}}
                     fullWidth
                     color="primary"
                     onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </Paper>
                <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}