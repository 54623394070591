import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Grid, TextField,FormControl,InputLabel,Select,MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, IconButton, Alert, Paper } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import { Api } from '../../consts/api';
import AllCourses from '../../consts/courses';
import {AiOutlineFileDone,AiFillCloseCircle} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import { Print } from '@mui/icons-material';
import FormPrint from './formPrint';

const steps = ['Persional Details', 'Communication Details', 'Programme Details'];

export default function RegistrationForm() {
  const pdfExportComponent = React.useRef(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const course = AllCourses()
  const navigate = useNavigate()
  const [formData, setFormData] = React.useState({
    name : '',
    dob : '',
    category : '',
    aye : '',
    f_name : '',
    ipt : '',
    e_name : '',
    m_name : '',
    g_name : '',
    designation : '',
    c_no : '',
    m_c_no : '',
    state : '',
    email : '',
    country : '',
    city : '',
    f_c_no : '',
    nationality : '',
    address : '',
    pin : '',
    course_type : '',
    stream : '',
    mos : '',
    faculty : '',
    h_facility : '',
    course : '',
    session : '',


  })
  const [err, setErr] = React.useState({})
  const [images, setImages] = React.useState({})
  const [img_send, setImageSend] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [dialogView, setDialogView] = React.useState({
    open : false,
    severity : null,
    massg : null
  })
  const [course_type, setCourseType] = React.useState([])
  const [last_insert_id, setLastInsertID] = React.useState(null)
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleChange = (e)=>{
    setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })
 }
 const handleChangeImage = (event)=>{    
  if(event.target.name === 'p_img'){
    setImageSend(URL.createObjectURL(event.target.files[0]))
  }       
  try {
      Resizer.imageFileResizer(
      event.target.files[0],
      800,
      1000,
      "PNG",
      80,
      0,
      (uri) => {
          setImages({
            ...images,
            [event.target.name] : uri
          })
      },
      "file",
      200,
      200
      );

  
  } catch (err) {
      console.log(err);
  }

}

 const handleVallidate = ()=>{
   let valid = true
   let err = {}

   Object.keys(formData).map((item)=>{
     if(!formData[item]){
       valid = false
       err[item] = true
       console.log(item)
     }
   })
   if(!images.pan){
     valid  = false
     err['pan'] = true
   }
   if(!images.p_img){
     valid = false
     err['p_img'] = true
   }
   if(!images.mark_hslc){
     valid = false
     err['mark_hslc'] = true
   }
   if(!images.mark_hs){
     valid = false
     err['mark_hs'] = true
   }
   if(!images.mark_degree){
     valid = false
     err['mark_degree'] = true
   }

   setErr(err)
   return valid
 }

 const onSubmit = ()=>{
   if(handleVallidate()){
    setLoading(true)
    const data = new FormData
    Object.keys(formData).map(item=>{
      data.append(item,formData[item])
    })
    data.append('pan',images.pan)
    data.append('p_img',images.p_img)
    data.append('mark_hslc',images.mark_hslc)
    data.append('mark_hs',images.mark_hslc)
    data.append('mark_degree',images.mark_degree)
    data.append('mark_pgdca',images.mark_pgdca)

    fetch(Api+'Register',{
      method : 'POST',
      body : data
    })
    .then(res=>res.json())
    .then(data=>{
      setLoading(false)
      if(data.code === 200){
        if (pdfExportComponent.current) {
          pdfExportComponent.current.save();
        }
        setDialogView({
          open : true,
          severity : 'success',
          massg : data.massg
        })
        setLastInsertID(data.id)
      }else{
        setDialogView({
          open : true,
          severity : 'error',
          massg : data.massg
        })
      }
    })
    .catch(err=>{
      setDialogView({
        open : true,
        severity : 'error',
        massg : 'Something went wrong, Please try again'
      })
    })
   }
 }

 const [faculty, setFaculty] = useState([])
 useEffect(()=>{
  fetch(Api+'courseInfo')
  .then(res=>res.json())
  .then(res=>{
    setCourseType(res.course)
    setFaculty(res.faculty)
  })
  .catch(err=>{
     console.log(err)
  })
 },[])
  
 const downloadPdf = ()=>{
  if (pdfExportComponent.current) {
    pdfExportComponent.current.save();
  }
 }
  return (
    <Container maxWidth="md">
        <h1>Addmission Form</h1>
        <hr color="#e77817" style={{marginBottom:10}} />
        {/* <IconButton
                    onClick={() => {
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                >
                    <Print />
            </IconButton> */}
    <Box sx={{ width: '100%',}} component={Paper} style={{paddingTop:15}}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {
              activeStep === 0 ? (
                  <Grid container spacing={1} style={{padding: 15}}>
                      <Grid item sm={4}>
                            <TextField 
                                variant='outlined'
                                fullWidth
                                label="Candidate Name"
                                onChange={handleChange}
                                value={formData.name}
                                style={{marginTop: 10}}
                                name="name"
                                error={err.name}
                            />
                            <TextField 
                                variant='outlined'
                                fullWidth
                                helperText="Date of Birth"
                                type="date"
                                onChange={handleChange}
                                value={formData.dob}
                                style={{marginTop: 10}}
                                name="dob"
                                error={err.dob}
                            />
                            <TextField 
                                variant='outlined'
                                fullWidth
                                label="Category"
                                onChange={handleChange}
                                value={formData.category}
                                style={{marginTop: 10}}
                                name="category"
                                error={err.category}
                            />
                             <TextField 
                                variant='outlined'
                                fullWidth
                                label="Are You employed"
                                onChange={handleChange}
                                value={formData.aye}
                                style={{marginTop: 10}}
                                name="aye"
                                error={err.aye}
                            />
                      </Grid>
                      <Grid item sm={4}>
                            <TextField 
                                variant='outlined'
                                fullWidth
                                label="Fathers Name"
                                onChange={handleChange}
                                value={formData.f_name}
                                style={{marginTop: 10}}
                                name="f_name"
                                error={err.f_name}
                            />
                            <TextField 
                                variant='outlined'
                                fullWidth
                                helperText="Photo"
                                type="file"
                                onChange={handleChangeImage}
                                
                                name="p_img"
                                error={err.p_img}
                                style={{marginTop: 10}}
                                
                            />
                            <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">ID Proof Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.ipt}
                                label="ID Proof Type"
                                name="ipt"
                                error={err.ipt}
                                onChange={handleChange}
                                
                              >
                                <MenuItem value={'Pan Card'}>Pan Card</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField 
                                variant='outlined'
                                fullWidth
                                label="Employer Name"
                                onChange={handleChange}
                                value={formData.e_name}
                                style={{marginTop: 10}}
                                name="e_name"
                                error={err.e_name}
                            />
                      </Grid>
                      <Grid item sm={4}>
                            <TextField 
                                variant='outlined'
                                fullWidth
                                label="Mothers Name"
                                onChange={handleChange}
                                value={formData.m_name}
                                style={{marginTop: 10}}
                                name="m_name"
                                error={err.m_name}
                            />
                            <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.g_name}
                                label="Gender"
                                onChange={handleChange}
                                name="g_name"
                                error={err.g_name}
                                
                              >
                                <MenuItem value={'Male'}>Male</MenuItem>
                                <MenuItem value={'Female'}>Female</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField 
                                variant='outlined'
                                fullWidth
                                helperText="Pan Card"
                                type="file"
                                onChange={handleChangeImage}
                              
                                style={{marginTop: 10}}
                                name="pan"
                                error={err.pan}
                            />
                            <TextField 
                                variant='outlined'
                                fullWidth
                                label="Designation"
                               
                                onChange={handleChange}
                                value={formData.designation}
                                style={{marginTop: 10}}
                                name="designation"
                                error={err.designation}
                            />
                      </Grid>
                      <Grid item sm={4}>
                        <TextField 
                                variant='outlined'
                                fullWidth
                                helperText="Mark Sheet HSLC"
                                type="file"
                                onChange={handleChangeImage}
                              
                                style={{marginTop: 10}}
                                name="mark_hslc"
                                error={err.mark_hslc}
                        />
                      </Grid>
        
                        <Grid item sm={4}>
                        <TextField 
                                variant='outlined'
                                fullWidth
                                helperText="Mark Sheet HS"
                                type="file"
                                onChange={handleChangeImage}
                              
                                style={{marginTop: 10}}
                                name="mark_hs"
                                error={err.mark_hs}
                        />
                        </Grid>
                        <Grid item sm={4}>
                        <TextField 
                                variant='outlined'
                                fullWidth
                                helperText="Mark Sheet Degree"
                                type="file"
                                onChange={handleChangeImage}
                              
                                style={{marginTop: 10}}
                                name="mark_degree"
                                error={err.mark_degree}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <TextField 
                                variant='outlined'
                                fullWidth
                                helperText="Mark Sheet PGDCA(optional)"
                                type="file"
                                onChange={handleChangeImage}
                              
                                style={{marginTop: 10}}
                                name="mark_pgdca"
                                error={err.mark_pgdca}
                        />
                      </Grid>
                  </Grid>
              ) : activeStep === 1 ? (
                <Grid container spacing={1} style={{padding: 15}}>
                    <Grid item sm={4}>
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="Contact Number"
                            onChange={handleChange}
                            value={formData.c_no}
                            style={{marginTop: 10}}
                            name="c_no"
                            error={err.c_no}
                        />
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="Mothers Contact No"
                            onChange={handleChange}
                            value={formData.m_c_no}
                            style={{marginTop: 10}}
                            name="m_c_no"
                            error={err.m_c_no}
                        />
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="State"
                            onChange={handleChange}
                            value={formData.state}
                            style={{marginTop: 10}}
                            name="state"
                            error={err.state}
                        />
                        
                    </Grid>
                    <Grid item sm={4}>
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="Email Address"
                            onChange={handleChange}
                            value={formData.email}
                            style={{marginTop: 10}}
                            name="email"
                            error={err.email}
                        />
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="Country"
                            
                            onChange={handleChange}
                            value={formData.country}
                            style={{marginTop: 10}}
                            name="country"
                            error={err.country}
                        />
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="City"
                            onChange={handleChange}
                            value={formData.city}
                            style={{marginTop: 10}}
                            name="city"
                            error={err.city}
                        />
                        
                    </Grid>
                    <Grid item sm={4}>
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="Fathers Contact No"
                            onChange={handleChange}
                            value={formData.f_c_no}
                            style={{marginTop: 10}}
                            name="f_c_no"
                            error={err.f_c_no}
                        />
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="Nationality"
                            onChange={handleChange}
                            value={formData.nationality}
                            style={{marginTop: 10}}
                            name="nationality"
                            error={err.nationality}
                        />
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="Address"
                            onChange={handleChange}
                            value={formData.address}
                            style={{marginTop: 10}}
                            name="address"
                            error={err.address}
                        />
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="Pin Code"
                            onChange={handleChange}
                            value={formData.pin}
                            style={{marginTop: 10}}
                            name="pin"
                            error={err.pin}
                        />
                    </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1} style={{padding: 15}}>
                    <Grid item sm={4}>
                        {/* <TextField 
                            variant='outlined'
                            fullWidth
                            label="Course Type"
                            onChange={handleChange}
                            value={formData.course_type}
                            style={{marginTop: 10}}
                            name="course_type"
                            error={err.course_type}
                        /> */}
                        <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Course Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.course_type}
                                label="course_type"
                                onChange={handleChange}
                                name="course_type"
                                error={err.course_type}
                                
                              >
                              {
                                course_type.map((item,index)=><MenuItem key={index} value={item.course_type}>{item.course_type}</MenuItem>)
                              }
                              </Select>
                        </FormControl>
                        <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Stream</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.stream}
                                label="stream"
                                onChange={handleChange}
                                name="stream"
                                error={err.stream}
                                
                              >
                                <MenuItem value={'Arts'}>Arts</MenuItem>
                                <MenuItem value={'Scince'}>Scince</MenuItem>
                                <MenuItem value="Commerce">Commerce</MenuItem>
                              </Select>
                        </FormControl>
                        <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Mode of Study</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.mos}
                                label="Mode of Study"
                                onChange={handleChange}
                                name="mos"
                                error={err.mos}
                                
                              >
                                <MenuItem value={'Online'}>Online</MenuItem>
                                <MenuItem value={'Offline'}>Offline</MenuItem>
                              </Select>
                        </FormControl>
                        
                    </Grid>
                    <Grid item sm={4}>
                        {/* <TextField 
                            variant='outlined'
                            fullWidth
                            label="Faculty"
                            onChange={handleChange}
                            value={formData.faculty}
                            style={{marginTop: 10}}
                            name="faculty"
                            error={err.faculty}
                        /> */}
                         <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Faculty</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.faculty}
                                label="faculty"
                                onChange={handleChange}
                                name="faculty"
                                error={err.faculty}
                                
                              >
                              {
                                faculty.map((item,index)=><MenuItem key={index} value={item.name}>{item.name}</MenuItem>)
                              }
                              </Select>
                        </FormControl>
                        {/* <TextField 
                            variant='outlined'
                            fullWidth
                            label="Hostel Facility"
                            onChange={handleChange}
                            value={formData.h_facility}
                            style={{marginTop: 10}}
                            name="h_facility"
                            error={err.h_facility}
                        /> */}
                        <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Hostel Facility</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.h_facility}
                                label="Hostel Facility"
                                onChange={handleChange}
                                name="h_facility"
                                error={err.h_facility}
                                
                              >
                                <MenuItem value={'Yes'}>Yes</MenuItem>
                                <MenuItem value={'No'}>No</MenuItem>
                              </Select>
                        </FormControl>
                        
                        
                    </Grid>
                    <Grid item sm={4}>
                        {/* <TextField 
                            variant='outlined'
                            fullWidth
                            label="Course"
                            onChange={handleChange}
                            value={formData.course}
                            style={{marginTop: 10}}
                            name="course"
                            error={err.course}
                        /> */}
                        <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Course</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.course}
                                label="course"
                                onChange={handleChange}
                                name="course"
                                error={err.course}
                                
                              >
                              {
                                course.map((item,index)=>
                                <MenuItem value={item.name} key={index}>{item.name}</MenuItem>
                                )
                              }
                                
                                
                              </Select>
                        </FormControl>
                        <TextField 
                            variant='outlined'
                            fullWidth
                            label="Session"
                            onChange={handleChange}
                            value={formData.session}
                            style={{marginTop: 10}}
                            name="session"
                            error={err.session}
                        />
                        {/* <TextField 
                            variant='outlined'
                            fullWidth
                            label="Employer Name"
                            onChange={handleChange}
                            value={formData.e_name}
                            style={{marginTop: 10}}
                            name="e_name"
                            error={err.e_name}
                        /> */}
                        
                    </Grid>
                </Grid>
              )
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            
            {
              activeStep === steps.length - 1 ? (
                <div>
                  {
                    loading ? <CircularProgress /> : <Button onClick={onSubmit}>
                    Save
                  </Button>
                  }
                </div>
              ) : (
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              )
            }

            
          </Box>
        </React.Fragment>
      )}
    </Box>

    <Dialog open={dialogView.open}>
      <DialogContent style={{textAlign:'center'}}>
        {dialogView.severity === 'error' ? <AiFillCloseCircle color="#eb1b0c" size={56} /> : <AiOutlineFileDone size={56} color='#06d637' />}
            
            <Typography>{dialogView.massg}</Typography>
            {
              dialogView.severity != 'error' ? (
                <Alert severity="warning">
                  PDF not downloaded yet?
                  <Button onClick={downloadPdf} color="primary">Download</Button> 
                </Alert>

              ) : (
                <></>
              )
            }
      </DialogContent>
      <DialogActions>
            {
              dialogView.severity === 'error' ? (
                <Button color='primary' onClick={()=>setDialogView({})}>
                  Edit
                </Button>
              ) : ''
            }
            
            <Button color='secondary' onClick={()=>navigate('/')}>
                To Home
            </Button>
      </DialogActions>
    </Dialog>
    <div
                style={{
                    position: "absolute",
                    left: "-1000px",
                    top: 0,
                  }}
    > 
    <PDFExport  keepTogether='.keep'  paperSize="A4" margin={{top: 10,right: 10,bottom: 10,left: 10}} fileName='registration_form.pdf'  ref={pdfExportComponent}>
      <FormPrint data={formData}  image={img_send} last_insert_id={last_insert_id} />
    </PDFExport>
    </div>
    </Container>
  );
}