import React from "react";
import {
    Avatar,
    Container,
    Typography,
    Button,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    TableContainer,
    TableRow,
    TableCell,
    Table,
    Divider,
    IconButton,
    Toolbar
} from '@mui/material'
import Slider from "react-slick";
import AllCourses from '../../consts/courses'
import { ImageApi } from "../../consts/api";
import {AiOutlineCloseCircle} from 'react-icons/ai'

export default function Course(){
    const course = AllCourses()
    const [dialogView,setDialogView] = React.useState(false)
    const [dialogData, setDialogData] = React.useState({})
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
      };

    const onReadMore = (item)=>{
      setDialogView(true)
      setDialogData(item)
    }
    return(
        <Container style={{marginTop: 100}}>
        <h1>Courses we offered</h1>
        <hr color="#e77817" style={{marginBottom:30}} />
        <Slider {...settings}>
          
        {
          course.map((item,index)=>
          <div key={index}>
            
            <Paper style={{margin: 5,padding: 10}} variant="outlined">
            <div style={{display : 'flex',flexDirection:'row'}}>
            <Avatar style={{padding: 25,border: '5px solid', borderColor: '#fff'}} component={Paper}>
                <img src={ImageApi+'courses/'+item.img} width="230%" />
            </Avatar>
            <div style={{marginLeft: 5}}>
                <h3 style={{margin : 0}}>{item.name}</h3>
                <div className="course_description" dangerouslySetInnerHTML={{__html : item.content}}>

                </div>
                <Button color="primary" onClick={()=>onReadMore(item)}>Read More</Button>
            </div>
            
            </div>
            </Paper>
          </div>
          
          )
        }
          
        </Slider>
        <Dialog open={dialogView} onClose={()=>setDialogView(false)}>
          <DialogTitle>
            <Toolbar style={{justifyContent:'space-between',width:'100%',margin:0,padding: 0}}>
            <h3 style={{margin:0}}>Course Details</h3>
            <IconButton onClick={()=>setDialogView(false)}>
              <AiOutlineCloseCircle />
            </IconButton>
            </Toolbar>
          </DialogTitle>
          <Divider />
          <DialogContent>
          <div dangerouslySetInnerHTML={{__html : dialogData.content}}>

          </div>
          </DialogContent>
        </Dialog>
        </Container>
    )
}