import { Container, Paper,Grid, Typography } from "@mui/material";
import React from 'react'
import {ImageApi,Api} from '../../consts/api'
export default function Faculty(){
    const [data,setData] = React.useState([])
    React.useEffect(()=>{
        fetch(Api+'faculty')
        .then(res=>res.json())
        .then(data=>setData(data))
    },[])

    return(
        <Container maxWidth="sm">
        <h2>Faculty</h2>
        <hr color="#e77817" style={{marginBottom:30}} />
        {
        data.map((item,index)=>
         <Grid container spacing={1} component={Paper} key={index} style={{marginTop: 10}}>
             <Grid item xs={12} sm={4}>
                <img src={ImageApi+'faculty/'+item.img} style={{width: '100%'}} />
             </Grid>
             <Grid item xs={12} sm={8}>
                 <h3 style={{marginBottom: 0}}>{item.name}</h3>
                 <Typography style={{fontSize: 12}}>{item.department}</Typography>
                 <Typography style={{fontSize: 12}}>{item.designation}</Typography>
                 <div dangerouslySetInnerHTML={{__html: item.details}}>

                 </div>
             </Grid>
         </Grid>
        
        )
        }
        </Container>
    )
}