import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";


export default function NotFound(){

    return (
        <Container maxWidth="sm" style={{display:'flex',flexDirection : 'column',justifyContent: 'center',alignItems : 'center',height: 500}}>
            <h1>You lost in some where</h1>
            <Link to={'/'} style={{textDecoration: 'none'}}>
            <Button variant="contained" >To Home</Button>
            </Link>
            
        </Container>
    )
}