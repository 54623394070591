import React from "react";
import { Container,AppBar, Toolbar, Typography, Button, Drawer, IconButton, ListItem, ListItemIcon, ListItemText,List, Divider, Menu, MenuItem, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import {GiArchiveRegister} from 'react-icons/gi'
import {FaAddressCard} from 'react-icons/fa'
import {BiGroup,BiHomeAlt} from 'react-icons/bi'
import {RiGalleryLine} from 'react-icons/ri'
import {BsFillInfoCircleFill, BsPeopleFill} from 'react-icons/bs'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {HiMenuAlt1} from 'react-icons/hi'
import colors from "../consts/colors";
import {Api} from '../consts/api'
import { useNavigate } from "react-router-dom";
import {FaDiscourse} from 'react-icons/fa'

const list = [
    {
        name : 'Home',
        icon: <BiHomeAlt color="#fff" size={20} />,
        link: '/',
    },
    {
        name : 'Addmission Form',
        icon: <GiArchiveRegister color="#fff" size={20} />,
        link: '/RegistrationForm',
    },
    // {
    //     name : 'Addmission',
    //     icon: <FaAddressCard color="#fff" size={20} />,
    //     link: '/Addmission',
    // },
    {
        name : 'Faculty',
        icon: <BiGroup color="#fff" size={20} />,
        link: '/Faculty',
    },
    {
        name : 'Gallery',
        icon: <RiGalleryLine color="#fff" size={20} />,
        link: '/Gallery',
    },
    {
        name : 'Courses',
        icon: <FaDiscourse color="#fff" size={20} />,
        link: '/Courses',
    },
    {
        name : 'Administration',
        icon : <BsPeopleFill color="#fff" size={20} />,
        link : '/',
        sub_menu : true,
        type : 'administrator'
    },
    {
        name : 'About Us',
        icon: <BsFillInfoCircleFill color="#fff" size={20} />,
        link: '/Faculty',
        sub_menu : true,
        type : 'about'
    },
]

const loginMenu = [
    {
        name : 'Student Login',
        link : '/Login',
        login : true
    },
    {
        name : 'Faculty Login',
        link : '/FacultyLogin',
        login: true
    }
]

export default function AppBarMaked(){
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [open, setOpen] = React.useState(false)
    const [anchorE1, setAnchorE1] = React.useState(null)
    const [menuItem, setMenuItem] = React.useState([])
    const [aboutMenu, setAboutMenu] = React.useState([])
    const [loading,setLoading] = React.useState(false)
    const [Lists, setLists] = React.useState(list)
    const navigate = useNavigate();
    
    const handleMenu = (e,item, type)=>{
        setAnchorE1(e)
        if(item != loginMenu){
            setLoading(true)
            fetch(Api+'aboutContents',{
                method : 'POST',
                body : JSON.stringify({type : type})
            })
            .then(res=>res.json())
            .then(res=>{
                setAboutMenu(res)
                setLoading(false)
            })
            .catch(err=>{
               console.log(err)
            })
        }else {
            setAboutMenu(loginMenu)
        }
        
        
       
       
    }
    const handleMenuClose = ()=>{
        setAnchorE1(null)
        setAboutMenu([])
    }
    // const makeIcon = (item)=>{
    //     const i = item
    //     return (
    //         <div style={{fontSize : 10,textTransform : 'uppercase', border: '2px solid #fff', borderRadius: 50,textAlign: 'center',width: 20, height: 20, fontWeight : 'bold', display:'flex', alignItems:'center',justifyContent: 'center'}}>
    //             {i.charAt(0)}
    //         </div>
    //     )
    // }
    React.useState(()=>{
        fetch(Api+'nav_item')
        .then(res=>res.json())
        .then(res=>{
            // setLists([...Lists,res])
            res.map(item=>{
                // Lists.push({
                //     name : item.name,
                //     icon : null,
                //     link : '/Content'
                // })
                setLists([...Lists,{
                    name : item.name,
                    icon :  null,
                    link : '/Content',
                    content : item.content,
                    name : item.name
                }])
            })
        })
        .catch(err=>{
           console.log(err)
        })
    },[])

    const handleRoute = (item)=>{
        if(!item.login){
            navigate('/AboutUs/',{state : item})
            
        }else{
            navigate(item.link,{state : item})
        }
        setAnchorE1(null)
        setMenuItem([])
        setOpen(false)
        setAboutMenu([])
        
    }

    
   
    return (
        <>
        {
            !matches ? (
                <AppBar 
                    color="inherit" 
                    elevation={0}
                    style={{
                        borderBottom : '1px solid #ddd',
                        backgroundColor: colors.primary,
                        padding: 0,
                        
                    }}
                    position="sticky"
                >
                <Toolbar>
                <IconButton onClick={()=>setOpen(true)}>
                    <HiMenuAlt1 color="#fff" size={24} />
                </IconButton>
                </Toolbar>
                
                </AppBar>
            ) : (
                <AppBar 
                    color="inherit" 
                    elevation={0}
                    style={{
                        borderBottom : '1px solid #ddd',
                        backgroundColor: '#0d264a',
                        padding: 0
                    }}
                    position="sticky"
                >
                <Toolbar style={{justifyContent:'space-between'}}>
                    <Toolbar>
                        {
                            Lists.map((item,index)=>
                            <div key={index} style={{marginLeft: 10,marginRight: 15,marginTop: 5,textAlign:'center'}}>
                            {
                                !item.sub_menu ? (
                                    <Link  to={item.link} state={item} style={{textDecoration : 'none', color: '#fff',textAlign:'center',}}>
                                    {
                                        item.icon != null ? item.icon : (<div style={{fontSize : 10,textTransform : 'uppercase', border: '2px solid #fff', borderRadius: 50,textAlign: 'center',width: 20, height: 20, fontWeight : 'bold', display:'flex', alignItems:'center',justifyContent: 'center'}}>
                                        {item.name.charAt(0)}
                                    </div>)
                                    }
                                    <Typography style={{fontSize: 12}} >{item.name}</Typography>
                                    </Link>
                                ) : (
                                    <div onClick={({currentTarget : value})=>handleMenu(value,item.sub_menu,item.type)} style={{textDecoration : 'none', color: '#fff',textAlign:'center',cursor: 'pointer',margin : 0}}>
                                    {item.icon}
                                    <Typography style={{fontSize: 12}} >{item.name}</Typography>
                                    </div>
                                )
                            }
                                
                            </div>

                            )
                        } 
                    
                    </Toolbar>
                    <Toolbar>
                        
                        <Button variant="outlined" onClick={({currentTarget : value})=>handleMenu(value,loginMenu)} style={{borderColor: '#fff',color: '#fff'}}>
                             Login
                        </Button>
                       
                    </Toolbar>
                </Toolbar>
                </AppBar>
            )
        }
        <Drawer
            anchor="left"
            open={open}
            onClose={()=>setOpen(false)}
            sx={{
            
            width : 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            backgroundColor: '#0d264a',
                },
            }}
        >
        <List>
        {
            Lists.map((item,index)=>
            {
            return(
                !item.sub_menu ? (
                    <Link key={index} to={item.link} state={item} onClick={()=>setOpen(false)} style={{textDecoration : 'none', color: '#fff',textAlign:'center',marginRight: 15}}>
                    <ListItem >
                        <ListItemIcon>
                        {
                                            item.icon != null ? item.icon : (<div style={{fontSize : 10,textTransform : 'uppercase', border: '2px solid #fff', borderRadius: 50,textAlign: 'center',width: 20, height: 20, fontWeight : 'bold', display:'flex', alignItems:'center',justifyContent: 'center',color :'#fff'}}>
                                            {item.name.charAt(0)}
                                        </div>)
                                        }
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                    <Divider />
                    </Link>
                    
                ) : (
                    <div key={index}  state={item} onClick={({currentTarget : value})=>handleMenu(value,item,item.type)} style={{textDecoration : 'none', color: '#fff',textAlign:'center',marginRight: 15}}>
                    <ListItem >
                        <ListItemIcon>
                        {
                                            item.icon != null ? item.icon : (<div style={{fontSize : 10,textTransform : 'uppercase', border: '2px solid #fff', borderRadius: 50,textAlign: 'center',width: 20, height: 20, fontWeight : 'bold', display:'flex', alignItems:'center',justifyContent: 'center',color :'#fff'}}>
                                            {item.name.charAt(0)}
                                        </div>)
                                        }
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                    <Divider />
                    
                    </div>
                )
            )
            }
                
            )
        }
        </List>
        <Toolbar>
                        
                        <Button variant="outlined" onClick={({currentTarget : value})=>handleMenu(value,loginMenu)} style={{borderColor: '#fff',color: '#fff'}}>
                             Login
                        </Button>
                       
                    </Toolbar>
        </Drawer>
        <Menu
                        anchorEl={anchorE1}
                        open={Boolean(anchorE1)}
                        onClose={handleMenuClose}
                    >
                    {
                        loading ? <CircularProgress /> : ''
                    }
                    {
                        aboutMenu.map((item,index)=>
                        <div key={index} >
                            <MenuItem onClick={()=>handleRoute(item)}>
                                <Typography>{item.name}</Typography>
                            </MenuItem>
                            <Divider />
                        </div>
                        )
                    }
                    </Menu>
        </>
        
    )
}