import React from 'react'
import { Box, Button, CircularProgress, Container, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar } from '@mui/material'
import { Api } from '../../consts/api'
import { useNavigate } from 'react-router-dom'

export default function Addmission(){
    const navigate = useNavigate()
    const [email, setEmail] = React.useState('')
    const [err, setErr] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState([])

    const onCheck= ()=>{
        if(!email){
            setErr(true)
        }else{
          setLoading(true)
          fetch(Api+'checkAddmission',{
              method : 'POST',
              body : JSON.stringify({
                  email : email
              })
          })
          .then(res=>res.json())
          .then(data=>{
            setLoading(false)
              if(data.code === 200){
                navigate('/RegistrationForm')
              }else{
                setData(data)
              }
          })
          .catch(err=>{
            setLoading(true)
            alert('Something went wrong')
          })
        }
    }
    return (
        <Container maxWidth="sm">
            <h1>Addmisson Form</h1>
            <hr color="#e77817" style={{marginBottom:30}} />
            <Box style={{padding: 20}}>
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={({target : {value}})=>setEmail(value)}
                    error={err}
                />
                <Toolbar>
                <Button variant="contained" color='primary' onClick={onCheck} style={{marginTop: 5}}>
                    Next
                </Button>
                {loading ? <CircularProgress style={{marginTop: 5,marginLeft: 5}} /> : ''}
                </Toolbar>
                
            </Box>
            
            <TableContainer style={{padding: 20}}>
                <Table>
                {
                    data.length > 0 ? (
                        <>
                        <h4>Course Details</h4>
                            <TableRow>
                                <TableCell>Course Name</TableCell>
                                <TableCell>{data[0].name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Registration Fee</TableCell>
                                <TableCell>{data[0].reg_fee}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Addmission Fee</TableCell>
                                <TableCell>{data[0].addm_fee}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>I Card Fee</TableCell>
                                <TableCell>{data[0].i_card_fee}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell>{Number(data[0].reg_fee) + Number(data[0].addm_fee) + Number(data[0].i_card_fee)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Button variant='contained' color="primary">
                                        Pay Now
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </>
                    ) : ''
                }
                </Table>
            </TableContainer>
        </Container>
    )
}