import logo from './logo.svg';
import './App.css';
import AppBarMaked from './home/appbar';
import Home from './home/home';
import {BrowserRouter,Link,Routes,Route} from 'react-router-dom'
import TopBar from './home/topbar';
import Course from './home/course/course';
import Fotter from './home/fotter';
import RegistrationForm from './home/registration/form';
import Login from './home/auth/login';
import Addmission from './home/addmission/addmission';
import Faculty from './home/faculty/faculty_home';
import Gallery from './home/Gallery/gallery';
import { Alert } from '@mui/material';
import IndividualNotice from './home/others/individual_notice';
import CommonPage from './home/contents/common_page';
import FacultyLogin from './home/auth/faculty_login';
import FacultyHome from './home/faculty/my_account';
import AchademicRegister from './home/faculty/achademic_register'
import Comming from './home/faculty/comming';
import ProfileUpdate from './home/faculty/profile_update';
function App() {
  return (
    <div className="App" style={{backgroundColor :'#f3f3f3'}}>
      <BrowserRouter>
      <TopBar />
      <AppBarMaked />
      {/* <Alert severity="error">Under Construction</Alert> */}
      
      
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route  path="/RegistrationForm" element={<RegistrationForm />} />
        <Route  path="/Login" element={<Login />} />
        <Route  path="/Addmission" element={<Addmission />} />
        <Route  path="/Faculty" element={<Faculty />} />
        <Route  path="/Gallery" element={<Gallery />} />
        <Route  path="/IndividualNotice" element={<IndividualNotice />} />
        <Route  path="/AboutUs" element={<CommonPage />} />
        <Route  path="/Content" element={<CommonPage />} />
        <Route  path="/FacultyLogin" element={<FacultyLogin />} />
        <Route  path="/Courses" element={<Course />} />
        <Route   path="/FacultyHome" element={<FacultyHome />}>
            <Route path='/FacultyHome/AchademicRegister' element={<AchademicRegister />} />
            <Route path='/FacultyHome/Comming' element={<Comming />} />
            <Route path='/FacultyHome/ProfileUpdate' element={<ProfileUpdate />} />
            
        </Route>
      </Routes>
      <Fotter />
      </BrowserRouter>
    </div>
  );
}

export default App;
