import "./form_print.css"
import logo from '../../assets/logo.jpg'
import logo2 from '../../assets/logo2.jpeg'


export default function FormPrint(props){
    const data = props.data
    console.log(props.image)
    return(
        <div>
            <div style={{textAlign: 'center'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <img src={logo} style={{width : 50, height : 50}} />
                <div>
                    <div style={{fontSize: 16}}>GENOSIS INSTITUTE</div>
                    <div style={{fontSize: 14,textDecoration: 'underline'}}>Addmission Form</div>
                </div>
                <img src={logo2} style={{width : 50, height : 50}} />
            </div>
                
                <hr />
            </div>
    
                <div style={{width: '100%'}}>
                <div style={{display: 'flex',justifyContent:'flex-end'}}>
                    <img src={props.image} style={{width: 80, height: 100}} />
                </div>
                <div style={{fontSize: 12,fontWeight: 'bold',marginBottom: 2}}>Persional Details</div>
                    <table className="table">
                        <tr>
                            <td className="td">Application No</td>
                            <td className="td">{props.last_insert_id}</td>
                        </tr>
                        <tr>
                            <td className="td">Candidate Name</td>
                            <td className="td">{data.name}</td>
                        </tr>
                        <tr>
                            <td className="td">Fathers Name</td>
                            <td className="td">{data.f_name}</td>
                        </tr>
                        <tr>
                            <td className="td">Mothers Name</td>
                            <td className="td">{data.m_name}</td>
                        </tr>
                        <tr>
                            <td className="td">Date of Birth</td>
                            <td className="td">{data.dob}</td>
                        </tr>
                        <tr>
                            <td className="td">Gender</td>
                            <td className="td">{data.g_name}</td>
                        </tr>
                        <tr>
                            <td className="td">Category</td>
                            <td className="td">{data.category}</td>
                        </tr>
                        <tr>
                            <td className="td">ID Proof Type</td>
                            <td className="td">{data.ipt}</td>
                        </tr>
                        <tr>
                            <td className="td">Are You Employed?</td>
                            <td className="td">{data.aye}</td>
                        </tr>
                        <tr>
                            <td className="td">Employer Name</td>
                            <td className="td">{data.e_name}</td>
                        </tr>
                        <tr>
                            <td className="td">Designation</td>
                            <td className="td">{data.designation}</td>
                        </tr>

                    </table>
                </div>
                
            
            <div style={{width: '100%'}}>
                    <div style={{fontSize: 12,fontWeight: 'bold',marginBottom: 2,marginTop: 2}}>Communication Details</div>
                    <table className="table">
                        <tr>
                            <td className="td">Contact No</td>
                            <td className="td">{data.c_no}</td>
                        </tr>
                        <tr>
                            <td className="td">Email Address</td>
                            <td className="td">{data.email}</td>
                        </tr>
                        <tr>
                            <td className="td">Fathers Contact No</td>
                            <td className="td">{data.f_c_no}</td>
                        </tr>
                        <tr>
                            <td className="td">Mothers Contact No</td>
                            <td className="td">{data.m_c_no}</td>
                        </tr>
                        <tr>
                            <td className="td">Country</td>
                            <td className="td">{data.country}</td>
                        </tr>
                        <tr>
                            <td className="td">Nationality</td>
                            <td className="td">{data.nationality}</td>
                        </tr>
                        <tr>
                            <td className="td">State</td>
                            <td className="td">{data.state}</td>
                        </tr>
                        <tr>
                            <td className="td">City</td>
                            <td className="td">{data.city}</td>
                        </tr>
                        <tr>
                            <td className="td">Address</td>
                            <td className="td">{data.address}</td>
                        </tr>
                        <tr>
                            <td className="td">Pin Code</td>
                            <td className="td">{data.pin}</td>
                        </tr>
                        

                    </table>
                </div>
            <div style={{width: '100%'}}>
                    <div style={{fontSize: 12,fontWeight: 'bold',marginBottom: 2, marginTop: 2}}>Programme Details</div>
                    <table className="table">
                        <tr>
                            <td className="td">Course Type</td>
                            <td className="td">{data.course_type}</td>
                        </tr>
                        <tr>
                            <td className="td">Faculty</td>
                            <td className="td">{data.faculty}</td>
                        </tr>
                        <tr>
                            <td className="td">Course</td>
                            <td className="td">{data.course}</td>
                        </tr>
                        <tr>
                            <td className="td">Stream</td>
                            <td className="td">{data.stream}</td>
                        </tr>
                        <tr>
                            <td className="td">Hostel Facility</td>
                            <td className="td">{data.h_facility}</td>
                        </tr>
                        <tr>
                            <td className="td">Session</td>
                            <td className="td">{data.session}</td>
                        </tr>
                        <tr>
                            <td className="td">Mode of Study</td>
                            <td className="td">{data.mos}</td>
                        </tr>
                       
                    </table>
                </div>
                
        </div>
    )
}