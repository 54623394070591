import React from "react";
import { Container, FormControl,InputLabel,MenuItem, Select, TextField, IconButton, Tooltip, Paper, Typography, Button, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { Api } from "../../consts/api";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {AiFillSave} from 'react-icons/ai'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import SnakBar from '../../consts/massage'
export default function AchademicRegister(){
    const [formData, setFormData] = React.useState()
    const [err, setErr] = React.useState({})
    const [allSubject, setAllSubject] = React.useState([])
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const [loading,setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const [file, setFile] = React.useState()
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    React.useEffect(()=>{
        fetch(Api+'allSubject')
        .then(res=>res.json())
        .then(res=>setAllSubject(res))
        .catch(err=>{
           console.log(err)
        })
    },[])

    const handleChange = (e)=>{
        if(e.target.name === "file"){
            setFile(e.target.files[0])
        }else{
            setFormData(e.target.value)
        }
        
    }
    

    const onSubmit = ()=>{
        if(validate()){
            setLoading(true)
            const data = new FormData
            data.append('subject', formData)
            data.append('file',file)
            data.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
            data.append('email', localStorage.getItem('email'))
                    fetch(Api+'updateARegister',{
                      method : 'POST',
                      body : data
                    })
                    .then(res=>res.json())
                    .then(res=>{
                      setLoading(false)
                      if(res.code === 200){
                        setMassg({
                          open : true,
                          severity : 'success',
                          massg : res.massg
                        })
                      }else{
                        setMassg({
                          open : true,
                          severity : 'error',
                          massg : res.massg
                        })
                      }
                    })
                    .catch(err=>{
                      setLoading(false)
                      setMassg({
                        open : true,
                        severity : 'error',
                        massg : 'Faild to connect to the server'
                      })
                    })
        }
    }

    const validate = ()=>{
        let valid = true
        let errs = {}

        if(!formData){
            errs['subject'] = true
            valid = false
        }


        setErr(errs)

        return valid
    }

    return (
        <Container component={Paper}>
            <h3>Achademic Register</h3>
            <hr color="e77817" />

            <Box>
            {loading ? <LinearProgress /> : ''}
            <FormControl fullWidth style={{marginTop: 10}}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData}
                label="Subject"
                name="subject"
                error={err.subject}
                onChange={handleChange}
                                
                >
                {
                    allSubject.map((item,index)=>
                    <MenuItem key={index} value={item.id}>{item.subject_name}</MenuItem>
                    )
                }    
                
                
                </Select>
            </FormControl>
            <TextField
                helperText="Attachment"
                type="file"
                name="file"
                onChange={handleChange}
                fullWidth
                style={{marginTop : 10}}
            />
            <Typography>Description</Typography>
            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{border : '1px solid ',minHeight : 200, borderColor : err.editorState ? 'red' : '#e5e5e5'}}
                toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><AiFillSave /></Tooltip></IconButton>]}
               
                />
                <textarea
                disabled
                style={{display: 'none'}}
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                // onChange={({target : {value}})=>setData(value)}
            />
            <Button variant="contained" onClick={()=>onSubmit()} color="primary" style={{margin: 10}}>Save</Button>
            </Box>
        <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}