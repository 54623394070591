import React from 'react'
import {
    Container, Grid, Button, Toolbar
} from '@mui/material'
import AboutImage from '../assets/about.png'
import { Api } from '../consts/api'
import { useNavigate } from 'react-router-dom'
export default function AboutUsMin(){
    const navigate = useNavigate()
    const [data, setData] = React.useState([])
    React.useState(()=>{
        fetch(Api+'about_us')
        .then(res=>res.json())
        .then(res=>setData(res))
        .catch(err=>{
           console.log(err)
        })
    },[])

    return(
        <Container style={{marginTop: 100}}>
        {
            data.length > 0 ? (
                <Grid container>
                    <Grid item sm={4}>
                        <img src={AboutImage} width="100%"  />
                    </Grid>
                    <Grid item sm={8}>
                        <h1>Know more About us</h1>
                        <hr color="#e77817" style={{marginBottom:30}} />
                        <div className='about_us_text' dangerouslySetInnerHTML={{__html: data[0].content}}>

                        </div>
                        <Toolbar style={{justifyContent: 'flex-end'}}>
                        <Button variant="contained" onClick={()=>navigate('AboutUs',{state : data[0]})}>
                            Read More
                        </Button>
                        </Toolbar>
                    </Grid>
                </Grid>
            ) : ''
        }
            
        </Container>
    )
}