import { AppBar, Container, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Paper } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Api } from "../../consts/api";
import {AiOutlineMenuUnfold} from 'react-icons/ai'
import AchademicRegister from "./achademic_register";


const DrawerItem = [
    {
        name : 'Achademic Register',
        link : '/FacultyHome/AchademicRegister'
    },
    {
        name : 'Subject Allotment',
        link : '/FacultyHome/Comming'
    },
    {
        name : 'Profile',
        link : '/FacultyHome/ProfileUpdate'
    },
    {
        name : 'Logout',
    },
]
export default function FacultyHome(){
    const navigate = useNavigate()
    const [faculty, setFaculty] = React.useState([])
    const [dOpen, setDopen] = React.useState(true)
    React.useEffect(()=>{
        const email = localStorage.getItem('email')
        if(!email){
            navigate('/FacultyLogin')
        }else{
            fetch(Api+'facultyInfo',{
                method : 'POST',
                body : JSON.stringify({email : email})
            })
            .then(res=>res.json())
            .then(res=>setFaculty(res))
            .catch(err=>{
               console.log(err)
            })
        }
        
    },[])

    const handleList = (link)=>{
        navigate(link)
    }
    return(
        <Container>
        <AppBar position="relative" elevation={0} style={{alignItems: 'flex-start'}}>
        <IconButton onClick={()=>setDopen(dOpen ? false : true)}>
            <AiOutlineMenuUnfold color="#fff" />
        </IconButton>
        </AppBar>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
            <Paper>
            {
            dOpen ? (
                <List>
                        {
                            DrawerItem.map((item,index)=>
                                <div key={index}>
                                    <ListItem onClick={()=>handleList(item.link)} button>
                                        <ListItemText primary={item.name} />
                                    </ListItem>
                                    <Divider />
                                </div>
                            )
                        }
                        </List>
                    ) : ''
                }
            </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Outlet />
            </Grid>
        </Grid>
        </Container>
    )
}


