import React from 'react'
import { 
    Container,
    Paper,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    Grid
} from '@mui/material'
import { Api, ImageApi } from '../../consts/api';
import Slider from "react-slick";

export default function Gallery(){
    const [data, setData] = React.useState([])
    React.useState(()=>{
        fetch(Api+'galleryMin')
        .then(res=>res.json())
        .then(res=>setData(res))
    },[])


    return(
        <Container maxWidth="md">
        <h2>Gallery</h2>
        <hr color="#e77817" style={{marginBottom:30}} />
        <Grid container spacing={1}>
            {
            data.map((item,index)=>
           
                <Grid item xs={12} sm={3} key={index}>
                <Card sx={{ maxWidth: 445,marginRight: 1 }}>
                <CardActionArea>
                    <CardMedia
                    component="img"
                    height="140"
                    image={ImageApi+'gallery/'+item.image}
                    alt={item.image}
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {item.caption}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {item.description}
                    </Typography>
                    </CardContent>
                </CardActionArea>
                </Card>
                </Grid>
            
            
            )
          }
        </Grid>
        </Container>
    )
}